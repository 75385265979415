function slider(params) {
    let activeIndex = 0;
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;
    let isActive = shouldWork();

    const nav = document.querySelector(params.nav);
    const wrapper = document.querySelector(params.wrapper);
    let slides = document.querySelectorAll(params.item);

    slide();

    wrapper.addEventListener(
        'touchstart',
        event => {
            touchstartX = event.changedTouches[0].screenX;
            touchstartY = event.changedTouches[0].screenY;
        },
        { passive: true }
    );

    wrapper.addEventListener(
        'touchend',
        event => {
            touchendX = event.changedTouches[0].screenX;
            touchendY = event.changedTouches[0].screenY;
            isActive && handleSwipe(event);
        },
        { passive: true }
    );

    nav.addEventListener('click', e => {
        if (e.target.classList.contains('nav-item')) {
            activeIndex = Array.from(nav.children).indexOf(e.target);
            isActive & slide();
        }
    });

    function handleSwipe(e) {
        let caseCaptured = false;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 32) {
            // Swipe left
            caseCaptured = true;
            if (activeIndex < slides.length - 1) {
                activeIndex++;
                slide();
            }
        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe right
            caseCaptured = true;
            if (!activeIndex) {
                // If user swipes left and the slider is on the first image -> do nothing
                return;
            }

            activeIndex--;
            slide();
        }

        if (caseCaptured) {
            e.preventDefault();
        }
    }

    function slide() {
        if (!isActive) {
            return;
        }

        const idx = activeIndex;

        Array.from(nav.children).forEach((item, i) =>
            idx == i ? item.classList.add('active') : item.classList.remove('active')
        );

        setTimeout(() => {
            if (!slides.length) {
                return;
            }

            const activeSlide = slides[idx];
            const marginLeft = (function calc() {
                return -(
                    activeSlide.offsetWidth * idx +
                    parseInt(window.getComputedStyle(activeSlide).marginRight) * idx
                );
            })();

            slides[0].style.marginLeft = marginLeft + 'px';
        }, 50);
    }

    function shouldWork() {
        return params.from && params.to
            ? window.innerWidth > params.from && window.innerWidth < params.to
            : true;
    }

    function refresh() {
        const isActiveOld = isActive;
        isActive = shouldWork();
        if (!isActive) {
            slides[0].style.removeProperty('margin-left');
        }

        if (isActive && isActive !== isActiveOld) {
            slides = wrapper.querySelectorAll(params.item);
        }
    }

    return {
        refresh,
        slide
    };
}

function handleSlides(params) {
    let activeIndex = 0;
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;

    const nav = document.querySelector(params.nav);
    const wrapper = document.querySelector(params.wrapper);
    let slides = wrapper.querySelectorAll('.slide');

    slide();

    window.addEventListener('resize', e => {
        slides = wrapper.querySelectorAll('.slide');
        slide();
    });

    wrapper.addEventListener(
        'touchstart',
        event => {
            touchstartX = event.changedTouches[0].screenX;
            touchstartY = event.changedTouches[0].screenY;
        },
        { passive: true, useCapture: false }
    );

    wrapper.addEventListener(
        'touchend',
        event => {
            touchendX = event.changedTouches[0].screenX;
            touchendY = event.changedTouches[0].screenY;
            handleSwipe(event);
        },
        { passive: true, useCapture: false }
    );

    nav.addEventListener('click', e => {
        if (e.target.classList.contains('nav-item')) {
            activeIndex = Array.from(nav.children).indexOf(e.target);
            slide();
        }
    });

    function handleSwipe(e) {
        let caseCaptured = false;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 32) {
            // Swipe left
            caseCaptured = true;
            if (activeIndex < slides.length - 1) {
                activeIndex++;
                slide();
            }
        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe right
            caseCaptured = true;
            if (!activeIndex) {
                // If user swipes left and the slider is on the first image -> do nothing
                return;
            }

            activeIndex--;
            slide();
        }

        if (caseCaptured) {
            e.preventDefault();
        }
    }

    function slide() {
        if (window.innerWidth > sliderTriggerWidth) {
            return;
        }

        const idx = activeIndex;

        Array.from(nav.children).forEach((item, i) =>
            idx == i ? item.classList.add('active') : item.classList.remove('active')
        );

        setTimeout(() => {
            if (!slides.length) {
                return;
            }
            const activeSlide = slides[idx];
            const activeSlideW = activeSlide.offsetWidth;
            const parentW = wrapper.offsetWidth / 2;
            const marginLeft =
                idx === 0
                    ? (parentW - activeSlideW / 1.7).toFixed()
                    : (function measure() {
                          let value = 0;
                          for (let i = 0; i < idx; i++) {
                              value += slides[i].offsetWidth;
                          }
                          const margin =
                              parseInt(window.getComputedStyle(slides[0]).marginRight) * 2;
                          const itemsWidth = value + margin * (idx - 1);
                          const x = parentW - (margin + activeSlideW / 2);
                          return -(itemsWidth - x);
                      })();

            slides[0].style.marginLeft = marginLeft + 'px';
        }, 50);
    }
}

function arrowSlider(params) {
    let autoSlideInterval = null;
    let activeIdx = 0;
    const wrapper = document.querySelector(params.wrapper);
    const slides = wrapper.querySelectorAll(params.slide || '.slide');
    const arrows = wrapper.querySelectorAll(params.navArrow || '.nav-arrow');
    const bullets = wrapper.querySelectorAll(params.navItem || '.nav-item');

    slides.forEach(slide => {
        slide.style.width = wrapper.offsetWidth + 'px';
    });

    arrows.forEach(arrow => {
        arrow.addEventListener('click', e => {
            if (e.target.classList.contains('left')) {
                // slide left
                if (activeIdx === 0) {
                    return;
                }

                activeIdx--;
                slideTo(activeIdx);
            } else {
                // slide right
                if (activeIdx === slides.length - 1) {
                    return;
                }

                activeIdx++;
                slideTo(activeIdx);
            }
        });
    });

    bullets.forEach(bullet => {
        bullet.addEventListener('click', e => {
            activeIdx = Array.from(bullets).indexOf(e.target);
            slideTo(activeIdx);
        });
    });

    if (params.autoSlide > 0) {
        autoSlideInterval = autoSlide(params.autoSlide);
    }

    function slideTo(idx) {
        console.log(idx);
        slides[0].style.marginLeft = -idx * slides[0].offsetWidth + 'px';
        bullets.forEach(bullet => bullet.classList.remove('active'));
        bullets[idx].classList.add('active');
    }

    function autoSlide(ms) {
        return setInterval(() => {
            activeIdx = activeIdx === slides.length - 1 ? 0 : ++activeIdx;
            slideTo(activeIdx);
        }, ms);
    }
}

module.exports = {
    slider,
    arrowSlider,
    handleSlides
};
