function rand(length, characters) {
    let result = '';
    var len = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * len));
    }
    return result;
}

module.exports = {
    rand
};