function post(url, data) {
    return new Promise(resolve => {
        const xhr = new XMLHttpRequest();

        xhr.open('POST', url, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                try {
                    resolve(JSON.parse(this.responseText));
                } catch (error) {
                    resolve(false);
                }
            }
        };
        xhr.send(serialize(data));
    });
}

function serialize(obj) {
    const str = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    }
    return str.join('&');
}

module.exports = post;
