window.addEventListener('DOMContentLoaded', run);

async function run(e) {
    selectTab();
}

function selectTab() {
    let tabs = document.querySelectorAll('.tabs .tab');
    let tabContents = document.querySelectorAll('.mytabs');

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
            tabContents.forEach(content => {
                content.classList.remove('active');
            });
            tabs.forEach(tab => {
                tab.classList.remove('active');
            });
            tabContents[index].classList.add('active');
            tabs[index].classList.add('active');
        });
    });
}
