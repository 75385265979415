import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

let swiper = Swiper;
let init = false;

const sliderTriggerWidth = 1024;

function swiperCard() {
  if (window.innerWidth <= sliderTriggerWidth) {
    if (!init) {
      init = true;
      swiper = new Swiper(".swiper", {
        direction: "horizontal",
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 16,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    }
  } else if (init) {
    swiper.destroy();
    init = false;
  }
}
swiperCard();
window.addEventListener("resize", swiperCard);