const { rand } = require('../utils/math-utils');

function initSecret() {
    setTimeout(() => {
        let parent = document.createElement('div');
        let i = 0;

        document.body.appendChild(parent);

        while (i < 5) {
            const child = document.createElement('div');
            parent.appendChild(child);
            parent = child;
            i++;
        }

        parent.classList.add('xyz');
        parent.dataset.xyz =
            rand(1, 'zrtef') +
            rand(9, '13abcdefgzxcv_') +
            rand(2, '0123456789') +
            rand(5, 'asdfghjklmnb');
    }, 3000);
}

module.exports = {
    initSecret
};