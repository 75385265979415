function initModal(params) {
    const modalClass = params.modalClass;
    const triggerBtn = params.triggerBtnClass;

    document.querySelectorAll(modalClass).forEach(node =>
        node.addEventListener('click', async e => {
            const popUpDiv = document.querySelector(triggerBtn);

            !popUpDiv.classList.contains('popup-visible')
                ? popUpDiv.classList.add('popup-visible')
                : popUpDiv.classList.remove('popup-visible');
        })
    );
}

module.exports = {
    initModal
};
