require('vanilla-cookieconsent/dist/cookieconsent');
require('vanilla-cookieconsent/dist/cookieconsent.css');
const cc = initCookieConsent();

cc.run({
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
        console.log('cookies -> onFirstAction');
    },

    onAccept: function (cookie) {
        // ...
        console.log('cookies -> onAccept');
    },

    onChange: function (cookie, changed_preferences) {
        // ...
        console.log('cookies -> onChange');
    },

    gui_options: {
        consent_modal: {
            layout: 'bar', // box/cloud/bar
            position: 'bottom center', // bottom/middle/top + left/right/center
            transition: 'slide' // zoom/slide
        }
    },

    languages: {
        en: {
            consent_modal: {
                title: 'Cookies on Domainer.com',
                description:
                    'We use cookies and other tracking technology to enhance your experience with our website and to make your visit to our website more enjoyable. Full details can be found under “Details” and in our <span class="privacy link">Privacy Policy</span>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all' // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Cookie Settings',
                    role: 'settings' // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie Settings',
                save_settings_btn: 'Save Settings',
                accept_all_btn: 'Accept all',
                close_btn_label: 'Close',
                blocks: [
                    {
                        title: '1. Technically required',
                        description:
                            'These cookies are necessary for the website to function and cannot be deactivated. They are usually set to provide a service you have requested, such as personalizing your privacy preferences, logging in or filling in forms. You can set your browser to block or warn you about these cookies. Complete deactivation can result in reduced functionality of the website. These cookies do not store any personally identifiable information.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    },
                    {
                        title: '2. Analysis and statistics',
                        description:
                            'These cookies enable the website to provide enhanced functionality and personalization.  They allow us to count the visits, to know which pages are the most and least popular and to see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. They may be set by us or by third-party providers whose services we have added to our pages.<ul><li><strong>Google Analytics</strong></li></ul>',
                        toggle: {
                            value: 'analytics-ga', // your cookie category
                            enabled: false,
                            readonly: false
                        }
                    }
                ]
            }
        }
    }
});
