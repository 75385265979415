function loader(parent) {
    function show() {
        wrapper.classList.remove('hidden');
        overlay.classList.remove('hidden');
    }

    function hide() {
        wrapper.classList.add('hidden');
        overlay.classList.add('hidden');
    }

    function release() {
        parent.removeChild(wrapper);
        parent.removeChild(overlay);
        parent.style.removeProperty('position');
    }

    const wrapper = document.createElement('div');
    wrapper.classList.add('simple-loader-wrapper');

    const element = document.createElement('div');
    element.classList.add('simple-loader');
    wrapper.appendChild(element);

    const overlay = document.createElement('div');
    overlay.classList.add('simple-loader-overlay');

    parent.appendChild(wrapper);
    parent.appendChild(overlay);
    parent.style.position = 'relative';

    return {
        wrapper,
        element,
        overlay,
        show,
        hide,
        release
    };
}

module.exports = loader;
